import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import PageBanner from "../layouts/PageBanner";
import bg from "../assets/images/background/dtc_williston_intro_robotic.webp";
import RecentProjectsSlider from "../components/Home/RecentProjectsSlider";
import Footer from "../layouts/Footer";

const allCategories = [
  {
    name: "Robotics Workshops",
    slug: "robotics-workshops",
    icon: "flaticon-robot-arm",
    description: "Regular sessions in Montgomery County, MD for middle and high school students.",
    location: "USA"
  },
  {
    name: "Summer Coding Camps",
    slug: "summer-coding-camps",
    icon: "flaticon-code",
    description: "Annual coding camps in Wilmington, NC focusing on hardware, Scratch, and robotics.",
    location: "USA"
  },
  {
    name: "International Outreach",
    slug: "international-outreach",
    icon: "flaticon-globe",
    description: "Technology education programs in Guinea and Côte d&apos;Ivoire, empowering girls and youth.",
    location: "Africa"
  },
  {
    name: "Conference Workshops",
    slug: "conference-workshops",
    icon: "flaticon-presentation",
    description: "Pop-up workshops at events like the Arise 2K24 Conference in Abidjan, Côte d&apos;Ivoire.",
    location: "Africa"
  }
];

const ProjectCategories = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [locationFilter, setLocationFilter] = useState("All");

  const filteredCategories = useMemo(() => {
    return allCategories.filter(category => 
      (category.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
       category.description.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (locationFilter === "All" || category.location === locationFilter)
    );
  }, [searchTerm, locationFilter]);

  const handleLocationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLocationFilter(e.target.value);
  };

  return (
    <>
      <div className="page-content bg-white">
        <PageBanner
          maintitle="Programs"
          pagetitle="Our Program Categories"
          background={bg}
        />
        <div className="content-inner-2">
          <div className="container">
            <div className="section-head text-center">
              <h2 className="title mb-4">Empowering Through <span className="text-primary">Technology Education</span></h2>
              <p className="lead mb-0">
                Our programs are designed to empower youth and women with:
              </p>
              <ul className="list-unstyled mt-3">
                <li className="mb-2">
                  <i className="fas fa-laptop-code text-primary mr-2"></i>
                  <span className="text-primary font-weight-bold">Digital Literacy</span>
                </li>
                <li className="mb-2">
                  <i className="fas fa-brain text-success mr-2"></i>
                  <span className="text-success font-weight-bold">Critical Thinking</span>
                </li>
                <li className="mb-2">
                  <i className="fas fa-puzzle-piece text-info mr-2"></i>
                  <span className="text-info font-weight-bold">Problem-Solving Skills</span>
                </li>
              </ul>
              <p className="mt-3">
                Equipping participants for exciting opportunities in our tech-driven future.
              </p>
              <div className="mt-4">
                <hr className="divider my-4" />
                <i className="flaticon-code text-primary icon-lg d-block mb-3"></i>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search programs..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <select
                  className="form-control"
                  value={locationFilter}
                  onChange={handleLocationChange}
                >
                  <option value="All">All Locations</option>
                  <option value="USA">USA</option>
                  <option value="Africa">Africa</option>
                </select>
              </div>
            </div>
            <div className="row">
              {filteredCategories.map((category, index) => (
                <div className="col-xl-3 col-lg-4 col-sm-6" key={index}>
                  <div className="icon-bx-wraper style-1 m-b30">
                    <div className="icon-lg m-b30">
                      <Link to={`/programs/${category.slug}`} className="icon-cell">
                        <i className={category.icon}></i>
                      </Link>
                    </div>
                    <div className="icon-content">
                      <h5 className="dz-tilte text-capitalize">
                        <Link to={`/programs/${category.slug}`}>{category.name}</Link>
                      </h5>
                      <p>{category.description}</p>
                      <span className="badge bg-primary">{category.location}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <section className="content-inner-2 overflow-hidden">
          <div className="container">
            <div className="section-head text-center">
              <h2>Recent Programs</h2>
              <p>
                Explore our latest initiatives and the impact we&apos;re making in communities around the world.
              </p>
            </div>
            <RecentProjectsSlider />
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default ProjectCategories;