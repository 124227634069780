export interface MediaItem {
  id: string;
  media_type: string;
  caption: string;
  permalink: string;
  timestamp: string;
  resizedImageUrl: string | string[];
  children?: {
    media_type: string;
    media_url: string;
    id: string;
  }[];
}

export async function fetchMediaData(page: number, limit: number): Promise<{ items: MediaItem[], total: number, hasMore: boolean }> {
  try {
    const response = await fetch(`${process.env.REACT_APP_CLOUDFLARE_R2_PUBLIC_URL}/resized/metadata.json`);
    if (!response.ok) {
      throw new Error('Failed to fetch metadata');
    }
    const allData: MediaItem[] = await response.json();
    
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    const paginatedItems = allData.slice(startIndex, endIndex);
    
    return {
      items: paginatedItems,
      total: allData.length,
      hasMore: endIndex < allData.length
    };
  } catch (error) {
    console.error('Error fetching media data:', error);
    throw error;
  }
}
