import React from "react";

import PageBanner from "../layouts/PageBanner";

import bg from "../assets/images/background/dtc_robotic_bg.webp";
import shape1 from "../assets/images/pattern/shape1.webp";
import shape3 from "../assets/images/pattern/shape3.webp";
import shape5 from "../assets/images/pattern/shape5.webp";
import shape6 from "../assets/images/pattern/shape6.webp";
import shape7 from "../assets/images/pattern/shape7.webp";
import ContactWithRecaptcha from "../components/dynamic/ContactRecaptcha";
import Footer from "../layouts/Footer";

const ContactUs: React.FC = () => {
  return (
    <div className="page-content bg-white">
      <PageBanner maintitle="Contact" pagetitle="Contact Us" background={bg} />
      <section className="content-inner-1 bg-light section-pattren1">
        <img src={shape1} className="shape-1 move-1" alt="shape" />
        <img src={shape3} className="shape-3 move-1" alt="shape" />
        <img src={shape5} className="shape-4 rotating" alt="shape" />
        <img src={shape6} className="shape-5 rotating" alt="shape" />
      </section>
      <section className="content-inner map-wrapper1">
        <div className="container-fluid">
          <div className="map-iframe style-1">
            <ContactWithRecaptcha />
          </div>
          <img src={shape5} className="shape-2 move-2" alt="shape" />
          <img src={shape7} className="shape-1 move-2" alt="shape" />
          <img src={shape6} className="shape-3 move-2" alt="shape" />
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ContactUs;
