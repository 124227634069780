import React from "react";
import { Link } from "react-router-dom";

import PageBanner from "../layouts/PageBanner";
import ProgramMasonry from "../components/Program/ProgramMasonry";

import bg from "../assets/images/background/dtc_robotic_bg.webp";
import Footer from "../layouts/Footer";

const Program = () => {

  return (
    <>
      <div className="page-content bg-white">
        <PageBanner maintitle="Our Programs" pagetitle="Programs" background={bg} />
        <div className="container">
          <div className="program-overview">
            <h3 className="text-primary mb-3">Empowering the Next Generation</h3>
            <p className="lead">
              Our innovative programs are designed to:
            </p>
            <ul className="list-unstyled">
              <li><i className="fas fa-laptop-code text-success mr-2"></i> Foster digital literacy</li>
              <li><i className="fas fa-brain text-warning mr-2"></i> Enhance critical thinking</li>
              <li><i className="fas fa-puzzle-piece text-info mr-2"></i> Develop problem-solving skills</li>
            </ul>
            <p className="mt-3">
              We equip youth and women with the tools they need to thrive in our tech-driven future.
            </p>
            <Link to="/mission" className="btn btn-primary mt-3">Learn More About Our Mission</Link>
          </div>
        </div>
        <div className="find-bx-wrapper">
          <div className="container">
      
            <div className="tag-list">
              <span className="title text-black">Popular Programs:</span>
              <Link to={"#"}>Robotics,</Link>
              <Link to={"#"}>Coding,</Link>
              <Link to={"#"}>International,</Link>
              <Link to={"#"}>STEM</Link>
            </div>
          </div>
        </div>
        <section className="content-inner-2">
          <div className="container">
            <ProgramMasonry />
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default Program;
