import React, { lazy, Suspense } from "react";
import { Link } from "react-router-dom";

import PageBanner from "../layouts/PageBanner";
const OurMission = lazy(() => import("../components/OurMission"));
import { IMAGES } from "../constant/theme";

// Import images with proper typing
import bgImage from "../assets/images/background/dtc_williston_intro_robotic.webp";
import pic1 from "../assets/images/project/dtc_harvest_tech/harvest_tech_empow_robotics_1.webp";
import about from "../assets/images/project/dtc_harvest_tech/harvest_tech_empow_robotics_12.webp";
import bg4 from "../assets/images/background/dtc_robotic_bg.webp";
import { WrapperBlog } from "../interfaces/Interfaces";
import Footer from "../layouts/Footer";

// Define the IconBlog interface here instead of importing it
interface IconBlog {
  title: string;
  icon: string;
  content?: string; // Add this line
}

// Define component props if necessary (e.g., if props are passed in)
const AboutUs: React.FC = () => {
  const iconBlog: IconBlog[] = [
    {
      title: "Integrity",
      content:
        "We uphold the highest ethical standards, ensuring honesty and transparency in all our actions. Integrity guides us to do what&apos;s right, building trust with our participants, partners, and communities.",
      icon: "flaticon-shield",
    },
    {
      title: "Consistency",
      content:
        "Consistency is key to our impact. We deliver reliable, high-quality programs that provide continuous support, helping our participants grow and succeed over time.",
      icon: "flaticon-target",
    },
    {
      title: "Compassion",
      content:
        "Compassion drives our mission. We approach each program with empathy, creating a supportive and inclusive environment where everyone feels valued and empowered.",
      icon: "flaticon-heart",
    },
  ];

  const wrapperBlog: WrapperBlog[] = [
    { icon: "flaticon-globe", title: "Global Reach" },
    { icon: "flaticon-code", title: "Coding Workshops" },
    { icon: "flaticon-robot", title: "Robotics Programs" },
    { icon: "flaticon-graduation-cap", title: "Digital Literacy" },
  ];

  return (
    <div className="page-content bg-white">
      <PageBanner maintitle="Home" pagetitle="About Us" background={bgImage} />
      <section className="content-inner section-wrapper5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="section-head">
                <h2 className="mb-4">
                  Building self-confidence since 2016
                </h2>
                <p className="mb-4">
                  Daytocode&apos;s mission is to build self-confidence in youth
                  and women in underserved communities through education in
                  computer programming and technology, fostering economic
                  inclusion and digital literacy.
                </p>
              </div>
              {iconBlog.map((data, index) => (
                <div
                  className="icon-bx-wraper box-hover style-2 mb-4"
                  key={index}
                >
                  <div className="icon-lg">
                    <Link
                      to={"/services-details"}
                      className="icon-cell"
                      aria-label={`Learn more about ${data.title}`}
                    >
                      <i className={data.icon} aria-hidden="true"></i>
                    </Link>
                  </div>
                  <div className="icon-content">
                    <h3 className="dz-title mb-2 text-capitalize">
                      {data.title}
                    </h3>
                    <p>{data.content}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-lg-5 mb-4">
              <div className="dz-media">
                <img
                  src={pic1}
                  alt="Students participating in a Daytocode workshop"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="content-inner-2 overlay-primary-dark about-wrapper2 bg-img-fix"
        style={{
          backgroundImage: `url(${bg4})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container">
          <div className="about-bx2">
            <div className="row g-0">
              <div className="col-lg-4">
                <div className="dz-media">
                  <img
                    src={about}
                    alt="Our team working together"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="dz-info">
                  <h2 className="title mb-4">Why support Daytocode?</h2>
                  <p className="mb-4">
                    Your donation empowers youth and women in underserved
                    communities by providing access to essential technology
                    education. By funding Daytocode, you help bridge the digital
                    divide, build self-confidence, and create opportunities for
                    participants to thrive and contribute to their communities.
                    Every contribution directly impacts lives, fostering a more
                    equitable digital future.
                  </p>
                  <div className="signature">
                    <p className="text-dark mt-3 mb-0">Emmanuel Bamba</p>
                    <p className="text-dark mt-3 mb-0">Founder, Daytocode</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="content-inner">
        <div className="container">
          <div className="section-head text-center">
            <h2 className="mb-4">Why choose Daytocode</h2>
            <p>
              We envision a world where every individual, regardless of
              background, has the opportunity to thrive in the digital age,
              contributing to their community&apos;s development.
            </p>
          </div>
          <div className="row">
            {wrapperBlog.map((item, ind) => (
              <div className="col-lg-6 col-md-12 col-sm-12" key={ind}>
                <div className="icon-bx-wraper box-hover style-2 mb-4">
                  <div className="icon-lg">
                    <Link
                      to={"/services-details"}
                      className="icon-cell"
                      aria-label={`Learn more about ${item.title}`}
                    >
                      <i className={item.icon} aria-hidden="true"></i>
                    </Link>
                  </div>
                  <div className="icon-content">
                    <h3 className="dz-title mb-3 text-capitalize">
                      {item.title}
                    </h3>
                    <p>
                      {item.title === "Global Reach" &&
                        "Programs in Maryland, North Carolina, Guinea, and Côte d&apos;Ivoire impacting over 500 youth across three continents."}
                      {item.title === "Coding Workshops" &&
                        "Regular sessions and summer camps to foster digital literacy and problem-solving skills."}
                      {item.title === "Robotics Programs" &&
                        "Hands-on activities in robot assembly and programming, inspiring future innovators."}
                      {item.title === "Digital Literacy" &&
                        "Equipping underserved communities with essential tech skills for the digital age."}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="content-inner-2 bg-light section-pattren1">
        <div className="container">
          <div className="row about-bx3 align-items-center">
            <Suspense fallback={<div>Loading...</div>}>
              <OurMission />
            </Suspense>
          </div>
        </div>
        <img
          src={IMAGES.Shape1 as string}
          className="shape-1 move-1"
          alt=""
          aria-hidden="true"
        />
        <img
          src={IMAGES.Shape2 as string}
          className="shape-2 move-2"
          alt=""
          aria-hidden="true"
        />
        <img
          src={IMAGES.Shape3 as string}
          className="shape-3 move-1"
          alt=""
          aria-hidden="true"
        />
        <img
          src={IMAGES.Shape5 as string}
          className="shape-4 rotating"
          alt=""
          aria-hidden="true"
        />
        <img
          src={IMAGES.Shape6 as string}
          className="shape-5 rotating"
          alt=""
          aria-hidden="true"
        />
        <img
          src={IMAGES.Shape6 as string}
          className="shape-6 rotating"
          alt=""
          aria-hidden="true"
        />
      </section>
      <section className="content-inner-2">
        <div className="container">
          <div className="section-head text-center">
            <h2 className="mb-4">Daytocode News: Upcoming Coding Camp</h2>
            <p>
              Daytocode is empowering underserved communities with essential
              tech skills. After a successful coding camp in Abidjan, Côte
              d&apos;Ivoire, we&apos;re excited to announce our third coding camp in
              October 2024 at Harvest Church Olney, Maryland. This camp will
              introduce advanced coding and emerging technologies like AI and
              IoT.</p> 
              <p>Join us in our mission to close the digital divide—through
              participation, volunteering, or donations, you can help shape a
              brighter, tech-driven future.
            </p>
          </div>
          {/* <NewsSlider /> */}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default AboutUs;
