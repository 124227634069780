import React, { useEffect, useState } from 'react';
import { MediaItem, fetchMediaData } from '../../services/apiService';
import { Swiper, SwiperSlide } from 'swiper/react'; // Ensure Swiper and SwiperSlide are imported correctly
import { Autoplay, Pagination } from 'swiper/modules'; // Import Pagination module

// Update interface to match MediaItem from apiService
interface InstagramPost extends MediaItem {
  media_type: 'CAROUSEL_ALBUM' | 'IMAGE' | 'VIDEO';
  permalink: string;
  resizedImageUrl: string | string[];
  children?: { 
    id: string;
    media_type: string;
    media_url: string;
  }[];  // Updated children structure to match MediaItem
  caption: string;  // Add this line
  timestamp: string;  // Add this line
}



const mapToInstagramPost = (item: MediaItem): InstagramPost => {
  return {
    ...item,
    media_type: item.media_type as 'CAROUSEL_ALBUM' | 'IMAGE' | 'VIDEO',
  };
};

const InstagramFeed: React.FC = () => {
  const [posts, setPosts] = useState<InstagramPost[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadMediaData = async () => {
      try {
        setIsLoading(true);
        const { items } = await fetchMediaData(1, 3); // Fetch only the last three items
        const instagramPosts = items.map(mapToInstagramPost);
        setPosts(instagramPosts);
        setIsLoading(false);
      } catch (err) {
        setError('Error fetching metadata');
        setIsLoading(false);
      }
    };

    loadMediaData();
  }, []);

  const getResizedImageUrls = (media: MediaItem): string[] => {
    if (Array.isArray(media.resizedImageUrl)) {
      return media.resizedImageUrl;
    } else if (typeof media.resizedImageUrl === 'string') {
      return [480, 768, 1200, 1600].map(size => `${media.resizedImageUrl}-${size}.webp`);
    }
    return [];
  };

  return (
    <>
      <Swiper
        className="recent-blog p-b5"
        speed={1500}
        slidesPerView={3}
        spaceBetween={30}
        loop={true}
        autoplay={{
          delay: 3000,
        }}
        modules={[Autoplay]}
        breakpoints={{
          1200: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          320: {
            slidesPerView: 1,
          },
        }}
      >
        {isLoading && <div className="text-center mt-4"><div className="spinner-border" role="status"></div></div>}
        {error && <div className="alert alert-danger mt-4" role="alert">Error fetching photos: {error}</div>}
        {posts.map((post) => (
          <SwiperSlide key={post.id}>
            <div className="dz-card style-2 overlay-skew wow fadeInUp" data-wow-delay="0.2s">
              <div className="dz-media">
                <a href={post.permalink} target="_blank" rel="noopener noreferrer">
                  {post.media_type === 'CAROUSEL_ALBUM' && post.children ? (
                    <Swiper
                      slidesPerView={1}
                      pagination={{ clickable: true, type: 'bullets' }} // Enable dotted navigation
                      loop={true}
                      modules={[Pagination]} // Add Pagination module
                    >
                      {post.children.map((child) => (
                        <SwiperSlide key={child.id}>
                          <img src={child.media_url} alt="Instagram post" />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  ) : (
                    <img src={getResizedImageUrls(post)[0] || ''} alt="Instagram post" />
                  )}
                </a>
              </div>
              <div className="dz-info">
                
                <h6 className="dz-title">
                  <a href={post.permalink} target="_blank" rel="noopener noreferrer">
                    {post.caption.length > 35 ? `${post.caption.slice(0, 35)}...` : post.caption}
                  </a>
                </h6>
                <div className="progress-bx style-1">
                  <ul className="progress-tag">
                    <li className="raised">
                      <i className="las la-calendar"></i>{" "}
                      <span>{new Date(post.timestamp).toLocaleDateString()}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default InstagramFeed;
