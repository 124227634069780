import React from "react";
import PageBanner from "../layouts/PageBanner";
import CountUp from "react-countup";
import TestimonialSlider from "../components/Home/TestimonialSlider";

import bg from "../assets/images/banner/bnr3.webp";
import large4 from "../assets/images/project/dtc_harvest_tech/harvest_tech_empow_robotics_26.webp";
import large5 from "../assets/images/project/dtc_harvest_tech/harvest_tech_empow_robotics_6.webp";
import large6 from "../assets/images/project/dtc_harvest_tech/harvest_tech_empow_robotics_34.webp";
import Footer from "../layouts/Footer";

/**
 * 
 * _26, _34, 38, 21, 31, 12, 11, 9, 4, 5
 */

const ProjectStory: React.FC = () => {
  function isScrolledIntoView(elem: string) {
    const spliBox = document.querySelectorAll<HTMLElement>(elem);
    spliBox.forEach((item: HTMLElement) => {
      console.log("splitbox", item);
      const docViewTop = window.scrollY;
      const docViewBottom = docViewTop + window.innerHeight;
      const elemTop = item.getBoundingClientRect().top + docViewTop;
      const elemBottom = elemTop + item.offsetHeight;
      if (elemBottom <= docViewBottom && elemTop >= docViewTop) {
        item.classList.add("split-active");
      }
    });
  }

  window.addEventListener("scroll", () => {
    isScrolledIntoView(".split-box");
  });

  return (
    <>
      <div className="page-content bg-white">
        <PageBanner
          maintitle="Our Impact"
          pagetitle="Success Stories and Programs"
          background={bg}
        />
        <section className="content-inner-2">
          <div className="container">
            <div className="row align-items-center project-bx left m-b50">
              <div className="col-lg-6 col-md-12 m-b30">
                <div className="dz-media split-box">
                  <div>
                    <img src={large4} alt="Robotics Workshop" className="app-1" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 m-b30">
                <h3 className="m-b20">Robotics Workshops in Montgomery, MD</h3>
                <p className="m-b0">
                  Twice a month, we conduct engaging robotics workshops at Silver Spring and Olney Libraries. 
                  Middle and High School students participate in hands-on activities, including robot assembly 
                  and programming using Scratch and Arduino.
                </p>
                <div className="fund-count m-t20 m-b25">
                  <h2 className="text-primary">
                    <span className="counter">
                      <CountUp end={100} separator="," />
                    </span>+
                  </h2>
                  <h6>Workshops Conducted</h6>
                </div>
                <div className="author-wrappper">
                  <div className="author-content">
                    <div className="author-head">
                      <h6 className="author-name">Impact</h6>
                    </div>
                    <p>These workshops have seen consistent participation and have successfully nurtured young minds, inspiring them to pursue careers in technology.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center project-bx right m-b50">
              <div className="col-lg-6 col-md-12 m-b30">
                <h3 className="m-b20">Summer Coding Camps in Wilmington, NC</h3>
                <p className="m-b0">
                  In partnership with local schools and DAI, we conduct annual summer coding camps in New Hanover County. 
                  Participants explore hardware, learn Scratch coding, engage in robotics programming, and create innovative projects.
                </p>
                <div className="fund-count m-t20 m-b25">
                  <h2 className="text-primary">
                    <span className="counter">
                      <CountUp end={80} separator="," />
                    </span>%+
                  </h2>
                  <h6>Robotics-based Final Projects</h6>
                </div>
                <div className="author-wrappper">
                  <div className="author-content">
                    <div className="author-head">
                      <h6 className="author-name">Success Story</h6>
                    </div>
                    <p>The camp has successfully increased participants&apos; confidence in STEM, particularly among young girls.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 m-b30">
                <div className="dz-media split-box">
                  <img src={large5} alt="Summer Coding Camp" className="app-1" />
                </div>
              </div>
            </div>
            <div className="row align-items-center project-bx left">
              <div className="col-lg-6 col-md-12 m-b30">
                <div className="dz-media split-box">
                  <div>
                    <img src={large6} alt="International Outreach" className="app-1" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 m-b30">
                <h3 className="m-b20">International Outreach</h3>
                <p className="m-b0">
                  Our programs in Conakry, Guinea, and Abidjan, Côte d&apos;Ivoire focus on building self-confidence 
                  in girls through basic computer skills training and coding workshops. We&apos;ve restored computer labs 
                  and provided training to over 190 students in Guinea.
                </p>
                <div className="fund-count m-t20 m-b25">
                  <h2 className="text-primary">
                    <span className="counter">
                      <CountUp end={200} separator="," />
                    </span>+
                  </h2>
                  <h6>Students Impacted in Guinea</h6>
                </div>
                <div className="author-wrappper">
                  <div className="author-content">
                    <div className="author-head">
                      <h6 className="author-name">Impact</h6>
                    </div>
                    <p>Our initiatives have not only equipped students with essential digital skills but have also significantly contributed to gender equality in technology education.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="testimonial-wrapper1 content-inner-2">
          <div className="section-head text-center">
            <h2 className="title">Testimonials</h2>
            <p>
              Hear from our participants, volunteers, and partners about the impact of our programs.
              These stories showcase the transformative power of technology education and how it&apos;s
              shaping futures across different communities.
            </p>
          </div>
          <TestimonialSlider />
        </section>
        <Footer />
      </div>
    </>
  );
};

export default ProjectStory;
