import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import PageBanner from "../layouts/PageBanner";

// images
import bg from "./../assets/images/project/wilmington_nc/williston_intro_robotic_1.webp";
import test1 from "./../assets/images/project/dtc_harvest_tech/harvest_tech_empow_robotics_24.webp";
import test2 from "./../assets/images/project/dtc_harvest_tech/harvest_tech_empow_robotics_15.webp";
import test3 from "./../assets/images/project/dtc_harvest_tech/harvest_tech_empow_robotics_12.webp";
import test4 from "./../assets/images/project/dtc_harvest_tech/harvest_tech_empow_robotics_31.webp";
import shape1 from "./../assets/images/pattern/shape1.webp";
import shape2 from "./../assets/images/pattern/shape2.webp";
import shape3 from "./../assets/images/pattern/shape3.webp";
import shape5 from "./../assets/images/pattern/shape5.webp";
import shape6 from "./../assets/images/pattern/shape6.webp";
import { ImageProps } from "../interfaces/Interfaces";
import Footer from "../layouts/Footer";


function LeftImage({ sideimage, paraModal }: ImageProps) {
  return (
    <div className="col-lg-10 m-b50">
      <div className="testimonial-3">
        <div className="testimonial-media">
          <img src={sideimage} alt="" />
        </div>
        <div className="testimonial-content">
          <h5 className="testimonial-title">
            Coding has opened up so many possibilities for me
          </h5>
          <p className="testimonial-text">
            The biggest thing I learned was doing coding, learning how to do different stuff on a computer, and drawing on different apps. I think it&apos;s important because you can learn all kinds of things to make new apps when you&apos;re older.{" "}
            <Link to={"#"} onClick={() => paraModal(true)}>
              Read More
            </Link>
          </p>
          <div className="testimonial-info">
            <div className="quotes">
              <i className="fa-solid fa-quote-left"></i>
            </div>
            <div className="clearfix">
              <h5 className="testimonial-name">Olney</h5>
              <span className="testimonial-position">Summer Coding Camp, Wilmington, NC</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function RightImage({ mainImage, paraModal }: ImageProps) {
  return (
    <div className="col-lg-10 offset-lg-2 m-b50">
      <div className="testimonial-3 right">
        <div className="testimonial-content">
          <h5 className="testimonial-title">
            This knowledge has opened up new possibilities for me
          </h5>
          <p className="testimonial-text">
            Since I started using the computer, I&apos;ve learned so much. Now, I can use the mouse, manipulate files, and even create documents—things I never knew how to do before.{" "}
            <Link to={"#"} onClick={() => paraModal(true)}>
              Read More
            </Link>
          </p>
          <div className="testimonial-info">
            <div className="quotes">
              <i className="fa-solid fa-quote-left"></i>
            </div>
            <div className="clearfix">
              <h5 className="testimonial-name">Salima Toudoumoua</h5>
              <span className="testimonial-position">10th Grade Student, Koumandjan High School, Guinea</span>
            </div>
          </div>
        </div>
        <div className="testimonial-media">
          <img src={mainImage} alt="" />
        </div>
      </div>
    </div>
  );
}

const Testimonials = () => {
  const [paraModal, setParaModal] = useState(false);

  const hideModal = () => {
    setParaModal(false);
  };

  return (
    <>
      <div className="page-content bg-white">
        <PageBanner maintitle="Home" pagetitle="Success Stories" background={bg} />
        <section className="content-inner bg-light section-pattren1">
          <div className="container">
            <div className="row">
              <LeftImage paraModal={setParaModal} sideimage={test1} />
              <RightImage paraModal={setParaModal} mainImage={test2} />
              <LeftImage paraModal={setParaModal} sideimage={test3} />
              <RightImage paraModal={setParaModal} mainImage={test4} />
              <LeftImage paraModal={setParaModal} sideimage={test2} />
            </div>
          </div>
          <img src={shape1} className="shape-1 move-1" alt="shape" />
          <img src={shape2} className="shape-2 move-2" alt="shape" />
          <img src={shape3} className="shape-3 move-1" alt="shape" />
          <img src={shape5} className="shape-4 rotating" alt="shape" />
          <img src={shape6} className="shape-5 rotating" alt="shape" />
          <img src={shape6} className="shape-6 rotating" alt="shape" />
        </section>
      </div>
      <Modal
        className="modal fade modal-wrapper"
        id="read"
        show={paraModal}
        onHide={hideModal}
        centered
      >
        <div className="modal-body">
          <p>
            Coding has opened up so many possibilities for me, and I&apos;m excited about what I can create next. This experience has been eye-opening, and I&apos;m thankful for the opportunity to learn. I feel empowered to do more and maybe even pursue a career in technology.
          </p>
        </div>
      </Modal>
      <Footer />
    </>
  );
};

export default Testimonials;
