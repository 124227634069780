import React from "react";
import { Link } from "react-router-dom";

import PageBanner from "../layouts/PageBanner";
import bg from "../assets/images/project/dtc_harvest_tech/harvest_tech_empow_robotics_31.webp";

import GallerySlider from "../components/Fundraiser/GallerySlider";
import Footer from "../layouts/Footer";

const FundraiserDetail = () => {
  return (
    <>
      <div className="page-content bg-white">
        <PageBanner
          maintitle="Support Us"
          pagetitle="Empower Through Technology"
          background={bg}
        />
        <section className="content-inner-2">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-8 m-b30">
                <div className="fundraiser-single">
                  <div className="swiper fundraiser-gallery-wrapper">
                    <GallerySlider />
                  </div>
                  <h2 className="title">
                    Bridging Digital Divides Locally and Globally
                  </h2>
                  <p>
                    Since 2016, Daytocode has been empowering the next generation through technology education. Our mission is to build self-confidence in youth and women in underserved communities through education in computer programming and technology, fostering economic inclusion and digital literacy.
                  </p>
                  <p>
                    Your support helps us bridge the digital divide, providing underserved communities with access to technology education and empowering the next generation of innovators.
                  </p>

                  <h5>About the Fundraiser</h5>
                  <p>
                    With your generous donations, we can continue to expand our programs, purchase essential equipment, and provide scholarships for participants. Our initiatives span across Maryland, North Carolina, Guinea, and Côte d&apos;Ivoire, impacting hundreds of lives through robotics workshops, summer coding camps, and international outreach programs.
                  </p>

                  <ul className="fundraiser-bottom">
                    {/* ... existing social media buttons ... */}
                  </ul>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4">
                <aside className="side-bar sticky-top">
                  <div className="widget style-1 widget_donate">
                    <Link
                      to={""}
                      className="btn btn-donate btn-primary w-100"
                      data-bs-toggle="modal"
                      data-bs-target="#modalDonate"
                    >
                      <i className="flaticon-like me-3"></i> Donate Now
                    </Link>
                    {/* ... existing social media button ... */}
                  </div>

                  <div className="widget style-1 widget_fund">
                    <h3 className="title">$ 50,000</h3>
                    <p>
                      raised of <span>$ 100,000</span> goal
                    </p>
                    <div className="progress-bx style-1">
                      <div className="progress">
                        <div
                          className="progress-bar progress-bar-secondary progress-bar-striped progress-bar-animated"
                          role="progressbar"
                          style={{ width: "50%" }}
                        ></div>
                      </div>
                    </div>
                    <ul className="detail">
                      <li className="d-flex">
                        <h5>500+</h5>
                        <span className="ms-2">youth impacted</span>
                      </li>
                      <li className="d-flex">
                        <h5>100+</h5>
                        <span className="ms-2">workshops conducted</span>
                      </li>
                    </ul>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default FundraiserDetail;
