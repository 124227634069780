import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ContactForm from "./Contact";

const ContactWithRecaptcha: React.FC = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}>
      <ContactForm />
    </GoogleReCaptchaProvider>
  );
};

export default ContactWithRecaptcha;