import React from 'react';
import {Link} from 'react-router-dom';

import PageBanner from '../layouts/PageBanner';
// import UpdateBlog from '../components/Home1/UpdateBlog';

import bg from './../assets/images/banner/bnr2.webp';
import team1 from './../assets/images/team/team1.webp';
import team2 from './../assets/images/team/team2.webp';
import team3 from './../assets/images/team/team3.webp';
import team4 from './../assets/images/team/team4.webp';
import team5 from './../assets/images/team/team5.webp';
import team6 from './../assets/images/team/team6.webp';
import team7 from './../assets/images/team/team7.webp';
import team8 from './../assets/images/team/team8.webp';
import team9 from './../assets/images/team/team9.webp';
import team10 from './../assets/images/team/team10.webp';
import team11 from './../assets/images/team/team11.webp';
import team12 from './../assets/images/team/team12.webp';
import Footer from '../layouts/Footer';

const cardDataBlog = [
    {image:team1, title:"Kaylynn Donin", subtitle:"Managing Director"},
    {image:team2, title:"Jakob Bothman", subtitle:"District Accounts Analyst"},
    {image:team3, title:"Lindsey Botosh", subtitle:"National Web Assistant"},
    {image:team4, title:"Phillip Schleifer", subtitle:"National Intranet Specialist"},
    {image:team5, title:"Adam Jordon", subtitle:"Managing Director"},
    {image:team6, title:"Hawkins Junior", subtitle:"District Accounts Analyst"},
    {image:team7, title:"Johan Lee", subtitle:"National Web Assistant"},
    {image:team8, title:"Richard Hartisona", subtitle:"National Intranet Specialist"},
    {image:team9, title:"Lee Jordon", subtitle:"Managing Director"},
    {image:team10, title:"Jakob Bothman", subtitle:"District Accounts Analyst"},
    {image:team11, title:"Sarah Albert", subtitle:"National Web Assistant"},
    {image:team12, title:"Cheyenne Curtis", subtitle:"National Intranet Specialist"},
];

const Volunteer = () => {
    return (
        <>
            <div className="page-content bg-white">
                <PageBanner  maintitle="Home" pagetitle="Volunteer"  background={bg}/>
                <section className="content-inner-2">
                    <div className="container">
                        <div className="row">
                            {cardDataBlog.map((item, ind)=>(
                                <div className="col-xl-3 col-lg-4 col-sm-6" key={ind}>
                                    <div className="dz-team style-1 m-b30">
                                        <div className="dz-media">
                                            <Link to={"/instructor"}><img src={item.image} alt="" /></Link>
                                            <ul className="team-social">
                                                <li><a href="https://www.facebook.com/daytocode" target="_blank" rel="noreferrer" className="fab fa-facebook-f">&nbsp;</a></li>
                                                <li><a href="https://www.instagram.com/daytocode" target="_blank" rel="noreferrer" className="fab fa-instagram">&nbsp;</a></li>
                                                <li><a href="https://twitter.com/daytocode" target="_blank" rel="noreferrer" className="fab fa-twitter">&nbsp;</a></li>
                                            </ul>
                                        </div>
                                        <div className="dz-content">
                                            <h5 className="dz-name">{item.title}</h5>
                                            <span className="dz-position text-primary">{item.subtitle}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    );
};

export default Volunteer;